.ReactVirtualized__Table__headerRow {
  font-weight: 400;
  text-transform: none;
  border-bottom: 1px solid #cacaca;
  color: #379aff;
}

.ReactVirtualized__Table__headerColumn {
  display: flex;

  &:first-of-type {
    margin-left: 0;
  }
}

.ReactVirtualized__Table__row {
  border-bottom: 1px solid #cacaca;
}

.ReactVirtualized__Table__rowColumn {
  margin-left: 0 !important;
}

.ReactVirtualized__Table__Grid {
  outline: 0;
}
