.alert {
  height: auto;
  margin-top: 16px;
}

.cancelButton {
  margin: 0 12px;
}

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
}
