/* Avenir LT W01 65 Medium */
@font-face {
  font-family: 'Avenir';
  src: url('./fonts/e4d36745-a861-4a49-aa17-6510e67429d3.woff2') format('woff2'),
    url('./fonts/2cac77ec-9bc0-4ee7-87e4-27650190744f.woff') format('woff');
  font-style: normal;
}

/* AvenirLTW01-65MediumObl */
@font-face {
  font-family: 'Avenir';
  src: url('./fonts/695a9fa5-9f57-4800-9993-3684d520f107.woff2') format('woff2'),
    url('./fonts/e0af2f49-a399-482b-a54e-d745e268ec80.woff') format('woff');

  font-weight: 400;
  font-style: oblique;
}

@font-face {
  font-family: 'Avenir-Bold';
  src: url('./fonts/65cde95a-ac33-4c65-8198-e37857968d1a.woff2') format('woff2'),
    url('./fonts/7147ec3a-8ff8-4ec9-8c5c-bd1571dc6ae6.woff') format('woff');
  font-weight: 700;
}

/* AvenirLTW01-85HeavyObli */
@font-face {
  font-family: 'Avenir';
  src: url('./fonts/67a2c8ef-54d4-4ab7-b60c-20fb619e97ab.woff2') format('woff2'),
    url('./fonts/53554f6f-4b01-4815-87fe-82c49b8ba5a9.woff') format('woff');

  font-weight: 700;
  font-style: oblique;
}
