.patient-form-input-field {
  background-color: #fff;
  padding: 21px 0;
  margin: 0 32px;
  font-size: 18px;

   input[type='text'] {
     height: 45px;
   }

  .form-label {
    line-height: 45px;
    width: 220px;
    padding: 0 15px;
    margin: 0;
  }
}

.error-border {
  border-bottom: 1px solid red;
}

.regular-border {
  border-bottom: 1px solid rgb(238, 236, 236);
}

.form-group {
  margin: 0 15px 0px 3px;
}

.patient-form-input:last-child {
  .patient-form-input-field {
    border-bottom: 0;
  }
}

.inline-element {
  display: inline-block;
}
.error-wrapper {
  position: relative;
  .error-text {
    color: red;
    font-weight: bold;
    font-size: smaller;
    height: 15px;
    margin: 0 32px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.required {
  color: red;
}
