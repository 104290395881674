.icon {
  width: 100px;
  height: 100px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.danger {
  background-image: url('./images/danger-exclamationnoun.svg');
}

.warning {
  background-image: url('./images/warning-exlamation.svg');
}

.information {
  background-image: url('./images/information-info.svg');
}

.question {
  background-image: url('./images/warning-question.svg');
}
