.container {
  position: absolute;
  width: 96%;
  top: 100px;
  left: 2%;
  overflow: hidden;
  z-index: 100;
  border: 1px solid #929497;
  border-radius: 3px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.29);
  background-color: #f4f4f4;
}

.header {
  padding: 0 40px;
}

.body {
  font-size: 18px;
  padding: 16px 40px 0 40px;
}

.footer {
  padding: 16px;
}

.overlay {
  position: absolute;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
}
