.root {
  min-height: 60px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: 12px solid;
}

.heading {
  font-size: 14px;
  font-family: 'Avenir-Bold', 'Avenir';
  font-weight: 700;
}

.content {
  font-size: 12px;
  margin: 0;
}

.warning {
  border-color: #ffcc02;
  background: #fffae6;
}

.danger {
  border-color: #e70000;
  background: #fde6e6;
}
