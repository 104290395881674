@import '../../../scss/variables';

.spinner-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -($spinner-size/2);
  margin-top: -($spinner-size/2);

  border: 10px solid rgba(255, 255, 255, 0.2) !important;
  border-right-color: #fff !important;

  z-index: 1000;
}


@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
