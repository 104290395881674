// Colors
$medium-gray: #f0ecec;
$medium-dark-gray: #e0dede;
$dark-gray: #a7a4a4;
$very-dark-gray: #4b4b4b;

// UI Components
$search-field-border-color: #d0d1d2;
$search-field-color: #3e3d40;
$search-field-focus-box-shadow-color: #408dc1;
$search-field-placeholder-color: #9fa1a3;

// Patient Search
$patient-search-icon-color: #718ea9;
$patient-search-selected-row-bg-color: #cceefb;
$patient-search-row-hover-bg-color: #e7e7e9;

// Spinner
$spinner-size: 80px;
